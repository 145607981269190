// General navbar adjustments

.navbar-light .navbar-brand {
  color: $navbar-brand-color;
}

.nav-link {
    font-size: $nav-link-font-size;

    &-icon {
      color: $nav-link-icon-color;

      @include hover-focus {
        color: darken($nav-link-icon-color, 10);
      }

      &__wrapper {
        position: relative;
      }
    }
  }
