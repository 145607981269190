.Toastify__toast--error {
    padding: 10px 15px;
    border: 1px solid #f0506e;
    border-radius:3px;
    font-size:13px;
    line-height: 1.6;
    color:#f0506e;
    background-color:#fef4f6;

    .Toastify__close-button {
        color: #f0506e
    }
}

.Toastify__toast--default {
    padding: 10px 15px;
    border: 1px solid #32d296;
    border-radius:3px;
    font-size:13px;
    line-height: 1.6;
    color:#32d296;
    background-color:#e0fbf1;

    .Toastify__close-button {
        color: #32d296
    }
}