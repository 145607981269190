.table-questionnaires {
  padding: 10px;

  &__row {
    display: grid;
    grid-template-columns: 49% 4% 4%;
    margin: 15px 10px;
    align-items: center;

    .questionnaire-actionButton {
      background-color: rgb(240, 240, 240);
      padding: 0.4rem 0.5rem;
    }
    .questionnaire-actionButton:hover {
      background-color: rgb(170, 170, 170);
      cursor: pointer;
    }
  }
}
