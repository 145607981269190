.action-cell {
  background-color: #eff5fc;

  .btn.dropdown-toggle {
    color: white;
  }
}

.BaseTable__table-frozen-right {
  box-shadow: -1px 0 0 0 var(--th-border);
}

.table-actions.tooltip-container {
  padding: 0;
  box-shadow: 0 5px 15px 0 rgba(28, 43, 54, 0.14);
  background-color: var(--white);
  border: none;

  .dropdown-menu {
    right: 0;
    left: auto;
  }

  .dropdown-item:hover {
    color: #007bff;
  }

  .dropdown-menu-small .dropdown-item {
    padding: 0 10px;
    line-height: 30px;

    &.report {
      border-top: 1px solid #eaeaea;
    }

    &.report:last-child {
      border-top: none !important;
    }
  }

  .dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(63, 78, 90, 0.14);
    padding: 0;
  }

  .dropdown-item {
    padding: 4px 1.25rem;
    font-size: 14px;
    &:first-child {
      padding-top: 8px;
    }
    &:last-child {
      padding-bottom: 8px;
    }
    a {
      color: #002060;
    }
    a:hover {
      color: var(--info);
    }

    &.no-content {
      opacity: 0.5;
      color: #212529;
      &:hover {
        color: #212529;
      }
    }
  }

  .dropdown-item__questionnaire {
    padding: 0.8rem;
    color: black;
    button:hover,
    a:hover {
      color: #245cce;
      text-decoration: none;
    }
  }

  .dropdown-submenu:hover {
    position: absolute;
    left: auto;
    top: 0;
    right: 100%;
  }

  .tooltip-arrow[data-placement*="left"]::before {
    border-color: transparent transparent transparent white;
  }
  .tooltip-arrow[data-placement*="left"]::after {
    border-color: transparent transparent transparent white;
  }
}

.BaseTable__header-row,
.BaseTable__row {
  border-bottom-color: #f7f7f7;
}

.BaseTable__row-cell {
  padding: 10px 0px;
  margin: auto 5px;
}

.BaseTable__table-main {
  outline: none;
}

.BaseTable__header-cell {
  text-transform: capitalize;
  margin: auto 5px;
}

.BaseTable__row:hover,
.BaseTable__row--hovered {
  background-color: var(--pale-grey);
}

.BaseTable__header-row {
  border-bottom: none;
  box-shadow: inset 0 1px 0 0 var(--primary-light);
  background-image: linear-gradient(to bottom, #f8fbfe, #eff5fc);
}

.disburse-button {
  width: max-content;
}
