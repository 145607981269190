//
// Scroll Mixins
//

// Theme
@mixin perfect-scrollbar-theme($color) {
    &.ps {
        > .ps__rail-x {
            background-color: transparent;

            &:hover,
            &:focus {
                opacity: 1;
                background-color: transparent;

                > .ps__thumb-x {
                    opacity: 1;
                }
            }

            > .ps__thumb-x {
                background-color: $color;
                opacity: 1;

                &:hover,
                &:focus {
                    opacity: 1;
                    background-color: $color;
                }
            }
        }

        > .ps__rail-y {
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
                opacity: 1;

                > .ps__thumb-y {
                    opacity: 1;
                }
            }

            > .ps__thumb-y {
                background: #197BBD;
                opacity: 1;

                &:hover,
                &:focus {
                    opacity: 1;
                    background: #197BBD;
                }
            }
        }
    }
}

@mixin perfect-scrollbar-ver-size($size) {
    &.ps {
        > .ps__rail-y {
            width: $size;

            &:hover,
            &:focus {
                width: $size;
            }

            > .ps__thumb-y {
                width: $size;
                @include border-radius($border-radius !important);

                &:hover,
                &:focus {
                    width: $size;
                }
            }
        }
    }
}

@mixin perfect-scrollbar-hor-size($size) {
    &.ps {
        > .ps__rail-x {
            height: $size;

            &:hover,
            &:focus {
                height: $size;
            }

            > .ps__thumb-x {
                top: 0;
                height: $size;
                @include border-radius($border-radius !important);

                &:hover,
                &:focus {
                    top: 0;
                    height: $size;
                }
            }
        }
    }
}

@mixin perfect-scrollbar-ver-offset($offset) {
    &.ps {
        > .ps__rail-y {
            right: $offset;
        }
    }
}
.ps__thumb-y{
    background-color:#197BBD !important ;
}
// Scrollbar
.ps > .ps__rail-y > .ps__thumb-y,
.ps > .ps__rail-x > .ps__thumb-x {
    background-color: #197BBD;

    &:hover,
    &:focus {
        background-color: #197BBD;
    }
}

//
// Perfect Scrollbar
//

// Base
.scroll {
    position: relative;
	overflow: hidden;

    &.scroll-pull {
        padding-right: 12px;
        margin-right: -12px;

        .ps__rail-y {
            right: -2px;
        }
    }

    &.scroll-push {
        .ps__rail-y {
            right: 5px !important;
        }
    }

    // Customization
    @include perfect-scrollbar-ver-size($custom-scrollbar-size);
    @include perfect-scrollbar-hor-size($custom-scrollbar-size);
    @include perfect-scrollbar-theme($custom-scrollbar-color);
}
