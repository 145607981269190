.score-logic-setup-wrapper {
    width: calc(100% - 227px);
}

.score-logic-setup-buttons {
    padding-top: 75px;
}

.score-logic-setup-enter {
    opacity: 0.01;
}

.score-logic-setup-enter.score-logic-setup-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.score-logic-setup-exit {
    opacity: 1;
}

.score-logic-setup-exit.score-logic-setup-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}