// Override the diagram default styling

// // diagram background
// .sc-AxirZ {
//     // background-color: whitesmoke!important;
//     // background-image: none!important;
//     border: 1px doted whitesmoke!important;
//     height: 56vh!important;
//     overflow-y: scroll!important;
// }
//
// // entity outer container
// // iBtNbm when not clicked
// // sc-AxiKw doesn't change
// // sc-AxiKw iBtNbm react-draggable
// // lbZxLd when clicked
// .sc-AxiKw {
//     background: #00b38e!important;
//     color: white!important;
//     height: 85px!important;
// }
//
// .sc-AxiKw, .sc-AxiKw:before, .sc-AxiKw:after {
//   box-shadow: 0 0 10px #00b38e!important;
// }
//
// .sc-AxiKw:before, .sc-AxiKw:after {
//   content: ""!important;
//   top: 8px!important;
//   z-index: -1!important;
//   position: absolute!important;
//   width: 69px!important;
//   height: 69px!important;
//   transform: rotate(145deg) skew(15.5deg)!important;
//   background: #00b38e!important;
// }
//
// .sc-AxiKw:before {
//   left: -32px!important;
// }
//
// .sc-AxiKw:after {
//   right: -32px!important;
// }
//
//
// .lbZxLd {
//     background: #00b38e!important;
//     color: white!important;
//     height: 79px!important;
//     box-shadow: 0 0 10px grey!important;
// }
//
// .lbZxLd, .lbZxLd:before, .lbZxLd:after {
//   box-shadow: 0 0 10px #00b38e!important;
// }
//
// .lbZxLd:before, .lbZxLd:after {
//   content: ""!important;
//   top: 7px!important;
//   z-index: -1!important;
//   position: absolute!important;
//   width: 65px!important;
//   height: 65px!important;
//   transform: rotate(145deg) skew(17.5deg)!important;
//   background: #00b38e!important;
// }
//
// .lbZxLd:before {
//   left: -30px!important;
// }
//
// .lbZxLd:after {
//   right: -30px!important;
// }
//
// // entity inner container
// .dTyNNZ {
//     padding: 30px 30px!important;
// }
//
// // connector dots
// .qIrib:hover {
//   .lkQdyZ {
//     background: #00b38e!important;
//   }
// }

.entity-grid {
  display: grid!important;
  grid-template-columns: 90% 10%!important;
  height: 79px!important;
  width: 200px!important;
        background: #00b38e!important;
        color: white!important;
        height: 85px!important;
  &-text {
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    justify-content: center!important;
    align-items: center!important;
    font-size: 18px!important;
    margin: auto!important;
  }

  &-icon {
    display: grid!important;
    align-items: center!important;
  }
}

.entity-grid, .entity-grid:before, .entity-grid:after {
  box-shadow: 0 0 10px #00b38e!important;
}

.entity-grid:before, .entity-grid:after {
  content: ""!important;
  top: 8px!important;
  z-index: -1!important;
  position: absolute!important;
  width: 69px!important;
  height: 69px!important;
  transform: rotate(145deg) skew(15.5deg)!important;
  background: #00b38e!important;
}

.entity-grid:before {
  left: -32px!important;
}

.entity-grid:after {
  right: -32px!important;
}

.edit-entity-icon {
  top: 15px!important;
  position: absolute!important;
}

.add-entity-icon {
  position: absolute!important;
  top: 45px!important;
  right: 0px!important;
}


.text-center {
  text-align: center;
}

.hierarchy-modal-container {
  padding: 1.25rem 1.25rem!important;
  .form-group {
    margin-top: 10px!important;
  }
  h6 b {
    color: #185597;
  }
}
