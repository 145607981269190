// Main sidebar

.main-sidebar {
    top: 0;
    position: fixed;
    height: $main-sidebar-height;
    z-index: $main-sidebar-zindex;
    will-change: $main-sidebar-will-change;
    transition: $main-sidebar-transition;
    left: 60px;

    @include media-breakpoint-down(sm) {
        transform: translateX(-100%);
    }

    // Opened state
    &.open {
        transform: translateX(0);
        width: 190px;
    }

    .main-navbar {
        height: $main-navbar-height;
    }

    // Toggle sidebar
    .toggle-sidebar {

        &:hover {
            cursor: pointer;
        }
    }

    // Sidebar navbar brand
    .navbar-title {
        line-height: 1;
        font-weight: 500;
        font-size: 16px;
        @include media-breakpoint-down(md) {
            font-size: 90%;
        }
    }

    .nav-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: $main-sidebar-nav-wrapper-height;
        padding-top: 30px;
    }

    // Nav
    .nav {
        border-bottom: none;

        .nav-item,
        .nav-link {
            white-space: nowrap;
            min-width: 100%;
            max-width: 100%;
            overflow: hidden;
            will-change: $main-sidebar-nav-link-will-change;
            transition: $main-sidebar-nav-link-transition;
            margin: 0;
        }

        .nav-item {
            .nav-link {
                padding: 7px 22px;
                color: $main-sidebar-nav-link-color;

                svg {
                    transition: $main-sidebar-nav-link-icon-transition;
                    margin-right: $main-sidebar-nav-link-icon-margin-right;
                }

                path {
                    fill: black;
                }
            }

            &.active,
            .nav-link.active,
            &:hover,
            .nav-link:hover {
                color: $main-sidebar-nav-link-active-color;

                i {
                    color: $main-sidebar-nav-link-active-icon-color;
                }

                svg path {
                    fill: $main-sidebar-nav-link-active-icon-color;
                }
            }

        }

        // Nav Item -- No borders
        &--no-borders {
            .nav-item .nav-link {
                border-bottom: $main-sidebar-nav-no-borders-nav-link-border-bottom;
                &:after { display: none; }
            }

            .collapsing.dropdown-menu,
            .collapse.dropdown-menu {
                box-shadow: none;
                background-color:transparent;
                overflow: hidden;

                .dropdown-item {
                    border: none;
                    padding: 6px 0 6px 80px;
                    position: relative;
                    font-weight: 500;

                    &.active {
                        background-color:transparent;
                    }

                    &:focus {
                       outline: none;
                    }

                    &:after {
                        content: '';
                        border-left: 1px dotted #a1b6ca;
                        height: 32px;
                        width: 1px;
                        left: 44px;
                        bottom: 15px;
                        position: absolute;
                        display: block;
                    }
                    &:before {
                        content: '';
                        border-bottom: 1px dotted #a1b6ca;
                        width: 29px;
                        height: 1px;
                        left: 45px;
                        bottom: 14px;
                        position: absolute;
                        display: block;
                    }
                    &:first-child {
                        border: none;
                    }
                }
            }

            .dropdown-menu {
                box-shadow: $main-sidebar-nav-no-borders-dropdown-menu-box-shadow;

                .dropdown-item:first-child {
                    border-top: $main-sidebar-nav-no-borders-dropdown-item-first-border-top;
                }
            }
        }
    }

    // Dropdown menu
    .dropdown-menu {
        position: static !important;
        transform: translate(0) !important;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        border: none;
        padding: 0;
        box-shadow: $main-sidebar-dropdown-menu-box-shadow;

        .dropdown-item {
            padding: $main-sidebar-dropdown-item-padding-y $main-sidebar-dropdown-item-padding-x;
            border-bottom: $main-sidebar-dropdown-item-border;
            color: $main-sidebar-dropdown-item-color;
            font-size: $main-sidebar-dropdown-item-font-size;
            font-weight: $main-sidebar-dropdown-item-font-weight;

            @media (-webkit-min-device-pixel-ratio: 1.5),
                (min-resolution: 144dpi) {
                font-weight: $main-sidebar-dropdown-item-font-weight-retina;
            }

            &:hover,
            &.active {
                color: $main-sidebar-dropdown-item-active-color;
            }

            &:hover {
                background: $main-sidebar-dropdown-item-background-hover;
            }

            &.active {
                background-color: $main-sidebar-dropdown-item-background-color-active;
            }

            &:last-of-type {
                border-bottom: $main-sidebar-dropdown-item-last-border;
            }
        }

        .dropdown-divider {
            margin: 0;
        }
    }

    // Dropdown toggle
    .dropdown-toggle {
        position: relative;

        &::after {
            background-image: $dropdown-icon-image;
            background-position: $dropdown-icon-background-position;
            width: $dropdown-icon-width;
            height: $dropdown-icon-height;
            transition: $dropdown-icon-transition;
            border: none;
            position: absolute;
            top: 50%;
            right: 0.625rem;
            transform: translateY(-50%);
            opacity: .1;
            will-change: transform;
        }
    }

    .dropdown.show {
        .dropdown-toggle::after {
            transform: translateY(-50%) rotateZ(180deg);
        }
    }

    &__search {
        @include media-breakpoint-down(sm) {
            box-sizing: border-box;
            border-right: 0 !important;
            padding: 0.625rem 0;
            border-bottom: 1px solid $border-color;

            .input-group {
                margin: 0 !important;
            }
        }
    }

    &__nav-title {
        text-transform: $main-sidebar-nav-title-text-transform;
        margin: $main-sidebar-nav-title-margin;
        font-size: $main-sidebar-nav-title-font-size;
        letter-spacing: $main-sidebar-nav-title-letter-spacing;
        padding: $main-sidebar-nav-title-padding;
        font-weight: $main-sidebar-nav-title-font-weight;
        color: $main-sidebar-nav-title-color;
        border-bottom: $main-sidebar-nav-title-border-bottom;
    }
}


//
// Icon sidebar nav
//

.icon-sidebar-nav {
    .main-sidebar {
        transition: $main-sidebar-icon-nav-transition;
        width: $main-sidebar-icon-nav-width;

        @include media-breakpoint-down(sm) {
            width: 100%;

            span {
                opacity: 1 !important;
                font-size: inherit !important;
            }

            .main-sidebar__nav-title {
                color: $main-sidebar-nav-title-color;
            }
        }

        // Navbar Titles
        &__nav-title {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px;
            color: $main-sidebar-background;
            transition: color $transition-duration ease-in-out;
            will-change: color;
        }

        &:hover {
            width: 13.75rem;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            .nav-link {
                text-overflow: visible;

                span {
                    opacity: 1 !important;
                    font-size: inherit !important;
                }
            }

            // Navbar Titles
            .main-sidebar__nav-title {
                color: $main-sidebar-nav-title-color;
            }
        }

        .nav-wrapper {
            overflow: visible;
        }

        .nav .nav-link {
            text-overflow: initial;

            span {
                opacity: 0;
                font-size: 0;
                transition: opacity 200ms ease-in-out;
            }
        }

        .nav-item {
            overflow: visible;
        }

        .dropdown-toggle::after {
            background-size: 100%;
        }

        @include media-breakpoint-up(md) {
            .dropdown-menu {
                border-radius: 0 $border-radius $border-radius 0;
                position: absolute !important;
                left: 100% !important;
                width: 12.5rem;
                box-shadow: $card-small-box-shadow;
                transition: opacity $transition-duration ease-in-out;
                display: block;
                visibility: hidden;
                opacity: 0;

                &.show {
                    opacity: 1;
                    visibility: visible;
                }

                .dropdown-item {
                    &:first-of-type {
                        border-radius: 0 $border-radius 0 0;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                        border-radius: 0 0 $border-radius 0;
                    }
                }
            }
        }
    }

    .main-content {
        padding: 0 0 0 $main-sidebar-icon-nav-width;

        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }
}


//
// Common styles for both main navbar and
// main sidebar search elements.
//

.main-navbar__search,
.main-sidebar__search {
    .input-group-prepend {
        .input-group-text {
            font-size: 0.6875rem;
            padding: 0.75rem 1.0625rem;
        }
    }

    input.form-control {
        border: none;
        font-size: 0.8125rem;
        border-radius: 0;

        @include hover-focus {
            box-shadow: none;
        }
    }
}
