.ReactModal__Overlay {
  &--after-open {
    z-index: 2147483648;
  }
}

.site-modal {
  .comments {
    max-height: 300px;
    overflow-y: scroll;
  }
}
