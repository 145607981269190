.document-checklist {
    &__item {
        align-items: center;
        border-bottom: dotted 1px #c3d1de;
        line-height: 1.76;
    }

    &__section-title {
        font-size: 22px;
        padding-bottom: 4px;
        border-bottom: 4px solid #eef4f9;
        font-weight: 600;
        color: #002060;
        font-family: 'Rubik';
    }

    &__actions {
      align-content: center;
      align-items: center;
      display: flex;

      button {
        margin: 8px 5px;
      }

      label{
        margin: auto;
        margin-left: 30px;
      }

      .inactive-secondary {
        background-color: #b1c5d8;
        pointer-events: none;
        border: none;
      }

      .inactive-danger {
        background-color: #e4a3af;
        pointer-events: none;
        border: none;
      }
    }
}

.site-modal {
  &-body {
    max-height: 600px;
    height: fit-content;
    overflow-y: scroll;
  }

  &__actions {
    .btn-primary:disabled {
        background-color: transparent;
    }
  }

  .image-upload_container {
    width: 100%;
    height: 190px;
    border-radius: 4px;
    background-color: #f2f2f2;
    text-align: center;
    padding-bottom: 10px;
    border: 1px dotted #9b9b9b;

    .drag-photo_text {
      padding-top: 22.4px;
      padding-bottom: 8.4px;
      opacity: 0.6;
      font-size: 14px;
      color: #303030;
    }

    .or_text {
      opacity: 0.6;
      font-size: 14px;
      color: #9b9b9b;
    }

    .upload-image_button {
      margin-top: 7.2px;
      width: 139px;
      height: 34.7px;
      border-radius: 4px;
      background-color: #959595;
      color: white;
    }

    .image-input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  .active-upload {
    background-color: #e1edf9;
  }

  .preview_file {
    width: 100%;
  }

  .error-upload {
    background-color: #fddede;
  }
}




.site-modal__actions {
  .btn-primary:disabled {
      background-color: transparent;
  }
}
