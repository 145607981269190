// Dropdown adjustments

// Adjust dropdown icons
.dropdown {

    a {
        color: #212529;
        &:hover {
            color: #1e87f0;
        }
    }

    .fa,
    .material-icons {
        color: lighten($blueish-grey, 40);
        margin-right: 0.25rem;
    }

    &-item {
        &:hover {
            background-color: lighten($blueish-grey, 60);

            >.dropdown-submenu {
                display: block;
            }
        }
    }

    &-menu {
        border-radius: 4px;
        box-shadow: 0 5px 15px 0 rgba(63, 78, 90, 0.14);
    }

    &-submenu {
        position: absolute;
        left: 100%;
        top: 0;
        display: none;
        min-width: 10rem;
        padding: 10px 0;
        margin: 0;
        font-size: 1rem;
        color: #5A6169;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        box-shadow: 0 5px 15px 0 rgba(63, 78, 90, 0.14);
    }

    &-right {
        left: auto;
        right: 0;
    }

}
