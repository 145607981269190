.react-tabs {
    margin-top: 20px;
    position: relative;

    .form-group.action-group {
        position: absolute;
        top: -40px;
        right: 0;

        .btn {
            padding: 6px 12px;
        }
    }

    &__tab-list {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        background-color: #eef4f9;
    }

    &__tab {
        padding: 14px 30px;
        text-align: center;
        color: #3f547d;

        &--selected {
            background-color: var(--white);
            color: var(--primary);
            border-top: 2px solid var(--primary);
        }

        &-panel--selected {
            padding-top: 1px;
            background-color: var(--white);

            &:nth-child(4) {
                background-color: #f1f8ff;
            }
        }
    }
}
