div .rc-tooltip {
    z-index: 99999;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
    background-color: #002060;

    .rc-tooltip-inner {
        background-color: #002060;
        border: none;
        color: white;
        font-size: 11px;
        min-height: 14px;
    }

    &.rc-tooltip-placement-top .rc-tooltip-arrow-inner, .rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner, .rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
        border-top-color: #002060;
    }

    &.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
        border-top-color: #002060;
    }
}
