.document-setup_button-group {
  justify-content: flex-end;
  button {
    margin: auto 10px;
  }
  .edit {
    right: 90px;
    position: absolute;
  }
}
