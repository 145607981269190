@-webkit-keyframes zoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.01);
  }
  50% {
    transform: scale(1.015);
  }
  75% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.01);
  }
  50% {
    transform: scale(1.015);
  }
  75% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

select.form-control-sm:not([size]):not([multiple]) {
  min-width: 190px;
}

.graph-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 50px;

  .report-card {
    height: 150px;
    width: 90%;
    padding: 5px;
    color: #107d54;
    background-color: white;
    border-top: 4px solid #107d54;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 3px 3px #888888;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 10px 5px;

    &-title {
      text-align: center;
      font-size: 19px;
    }

    &-value {
      text-align: center;
      font-size: 35px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: -0.5px;
    }

    &-title {
      text-align: left;
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 17px;
    }

    svg {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .report-card:hover {
    -webkit-animation: zoom 800ms;
    animation: zoom 800ms;
  }
}

.cards-empty {
  width: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  height: 150px;
  div {
    margin-top: 100px;
  }
  svg {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 20px;
  }
}

.select-questionaire {
  right: 50px;
  position: absolute;
}

.btn-export-reports {
  background-color: #007457 !important;
  border-color: #005a44 !important;
  border-left-color: 1px solid whitesmoke !important;
  border-radius: 3px !important;
  &:active {
    color: white;
  }
}

// .btn.dropdown-toggle {
//     border-color: #007457;
// }

.btn-filter {
  color: #185597;
  border-color: #185597;
  background-color: #fff;
  box-shadow: none;
  margin-top: 0px;
}

.select-error {
  border: 1px solid red !important;
}

.chart-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center; /* Keeps chart centered */
}

.chart-wrapper canvas,
.chart-wrapper svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the chart fits properly */
}

.chart-heading {
  text-align: center;
}
