// Main navbar adjustments

.main-navbar .navbar {
    height: $main-navbar-height;
    padding: 0 0 0 15px;

    >* {
        display: flex;
    }

    .nav-link {
        min-width: $main-navbar-nav-link-min-width;
    }

    // Notifications
    .notifications {
        position: relative;

        @include media-breakpoint-down(sm) {
            position: static;

            .dropdown-menu {
                min-width: 100% !important;
                border-left: none;
            }
        }

        .badge {
            position: absolute;
            padding: $notifications-badge-padding-y $notifications-badge-padding-x;
            font-size: $notifications-badge-font-size;
            left: 50%;
            top: 50%;
        }

        .dropdown-menu {
            padding: 0;
            min-width: $notifications-dropdown-min-width;
            border-right: 0;
            left: auto;

            // Adjust the dropdown menu on smaller screens.
            @include media-breakpoint-down(sm) {
                left: 0;
                right: 0;
            }

            .dropdown-item {
                white-space: normal;
                display: flex;
                flex-flow: row;
                padding-top: $notifications-dropdown-item-padding-top;
                padding-bottom: $notifications-dropdown-item-padding-bottom;
                border-bottom: $notifications-dropdown-item-border-bottom;

                &:last-child {
                    border-bottom: 0;
                }
            }

            .notification {
                &__icon-wrapper {
                    display: flex;
                    padding: 0 5px;
                }

                &__icon {
                    background-color: $notifications-icon-background-color;
                    box-shadow: $notifications-icon-box-shadow;
                    width: $notifications-icon-width;
                    height: $notifications-icon-height;
                    line-height: 0;
                    display: block;
                    text-align: center;
                    margin: auto;
                    border-radius: 50%;

                    i {
                        color: $notifications-icon-color;
                        line-height: $notifications-icon-line-height;
                        font-size: $notifications-icon-font-size;
                        margin: 0;
                    }
                }

                &__content {
                    padding: $notification-content-padding;

                    p {
                        margin: 0;
                        line-height: 1.5;
                        font-size: $notification-content-paragraph-font-size;
                    }
                }

                &__category {
                    font-size: $notification-category-font-size;
                    color: $notification-category-color;
                    letter-spacing: $notification-category-letter-spacing;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    font-weight: 500;
                }

                // All notifications link.
                &__all {
                    display: block;
                    font-weight: 500;
                    font-size: 11px;
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
    }

    // User avatar
    .user-avatar {
        max-width: $user-avatar-max-width;
    }

    // Navbar
    .navbar-nav .dropdown-menu {
        position: absolute;
        right: 0;
        left: auto;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
    }

    .nav-link-icon {
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .nav-link-icon i {
        line-height: $main-navbar-nav-link-icon-line-height;
    }
}

// Main navbar shadow.
// .main-content > .main-navbar-wrapper,
.main-content>.main-navbar {
    box-shadow: $main-navbar-box-shadow;
}

a.flex.nav-link {
    display: flex;
}

.user-profile-header {
    .dropdown-toggle::after {
        position: absolute;
        right: -9px;
        top: 50%;
    }
}

.toggle-right {
    &::after {
        transform: rotate(270deg);
    }
}

.lang_selector::after {
    margin-top: 1.2rem;
}

// Language selector
.language-selector {
    cursor: pointer;
    width: 140px;
    background-color: white !important;
    &__wrapper {
        display: flex;
        justify-content: center;
        user-select: none;
        padding-top: 10px;
        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-right: 12px;
        }
        p {
            font-weight: 500;
            color: #002060;
        }
    }
    .notification__icon-wrapper.language-selector__item {
        color: #002060;
        display: inline-block;
        &:hover {
            color: #1e87f0;
        }
    }
    .dropdown-menu-small {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 5px 15px 0 rgba(63, 78, 90, 0.14);
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        width: 95%;
        margin-top: -0.75rem;
        .dropdown-item {
            font-weight: 500;
            color: #002060;
            display: inline-block;
            user-select: none;
        &:hover {
            color: #1e87f0;
            background-color: #fff;
        }
        }
    }
    &__dropdown {
        margin-top: 30px;
        select {
            font-weight: 500;
            color: #002060;
            option {
                font-weight: 500;
                color: #002060;
            }
        }
    }
}
