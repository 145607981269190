.form-group-section {
    font-size: 22px;
    font-weight: 500;
    color: #002060;
    border-bottom: 7px solid #eef4f9;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

label {
   color: #002060;
}

input.form-control {
    border-radius: 2px;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #c3d1de;
    background-color: #f8f8f8;
    height: 40px;
}

.fields-group {
    flex: 0 0 50%;
}

.legend {
    background-color: #eff5fc;
}

.legend-head {
    background-color: #a1b6ca;
    height: 30px;
}

.legend-row {
    height: 53px;
    line-height: 53px;
    padding-left: 17px;
}

.legend-title {
    font-size: 15px;
}

.x-legend-title {
    text-align: center;
    margin: 0;
}

.legend-label-rotate-right {
    text-align: center;
    position: absolute;
    left: -50px;
    transform: translate(0%) rotate(-90deg);
}