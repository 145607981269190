.p-10 {
  .btn-primary:hover {
    color: #90ee90;
    background: 1px solid blue;
  }
}

.required-text {
  font-size: small;
}

.beneficiary-header {
  margin: 0;
}
