.header-icon {
  display: inline;
  margin-left: 3px;
}

button:focus {
  outline: 0;
}

.assign-empty-message {
  padding: 10px;
}

.tooltip-container {
  z-index: 1;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-content.show {
  display: block;
}

.payment-dropdown {
  z-index: 1;
}

.done-icon {
  color: var(--primary);
  margin-left: auto;
}

.actioned-text {
  color: var(--primary);
}

.declined-text {
  color: var(--secondary);
}

.incomplete-text {
  color: red;
}

.decline-icon {
  color: #f40000;
  margin-left: auto;
}

.btn-bulk-submit {
  background-color: var(--primary) !important;
  padding: 10px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  float: right !important;
}

.btn-disabled {
  background-color: grey !important;
}

.name-semibold {
  font-weight: 600 !important;
}

.btn-vb {
  color: #fff !important;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #008d6a !important;
  &:hover {
    background-color: #007a5f !important;
  }
}

.btn-cancel {
  color: #f40000 !important;
  font-weight: bold !important;
}
