// Main sidebar

.mini-sidebar {
    top: 0;
    position: fixed;
    height: $main-sidebar-height;
    background: #002060;
    z-index: $main-sidebar-zindex;
    will-change: $main-sidebar-will-change;
    transition: $main-sidebar-transition;
    box-shadow: $side-shadow;
    width: 60px;

    @include media-breakpoint-down(sm) {
        transform: translateX(-100%);
        box-shadow: none;
    }

    svg path {
        fill: white;
    }
}
