select.custom-select  {
    border: solid 1px rgba(195, 209, 222, 0.68);
}

select.custom-select option {
    background: rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(to bottom, var(--white), var(--primary-light));
}

.group-select {
  background-color: var(--primary-light);
  margin-top: 0;
  &-label {
    text-align: center;
    align-self: center;
    width: 200px;
  }

  &-input{
    margin: 15px 30px;
    color: #002060;
  }
}
.tooltip-container{
  z-index: 1;
  .tooltip-body {
    cursor: pointer;
  }
}

form {
    background: transparent;
}
