.clientDetailsDropdown {
    display: flex;
    justify-content: flex-end;

    &__dropdown-item {
        margin-right: 5px;

        button {
            background-color: #60c1f7;
            border-color: #60c1f7;

            :hover > & {
                background-color: #60c1f7;
                border-color: #60c1f7;
                color: white;
            }
        }
    }
}
.clientDetails {
    width: 100;
    display: flex;
    justify-content: space-between;

    &__section {
        width: 43%;

        &_row {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;

            p {
                margin-block-start: 0;
                margin-block-end: 0;
            }
            p:last-child {
                color: #107d54;
            }
        }
    }
}

// document upload override
.MuiDropzoneArea-root {
    min-height: unset !important;
}
.MuiDropzoneArea-text {
    font-size: 16px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #008d6a !important
}

// override date-time picker
.react-date-picker__wrapper {
    border: none !important;
}
