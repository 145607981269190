.main-content {
    table {
        color: $secondary-color;
    }

    thead {
        background-color: #eef4f9;
        font-weight: bold;
        box-shadow: inset 0 1px 0 0 $primary-light;
        background-image: linear-gradient(to bottom, #f8fbfe, #eff5fc);
    }

    .MuiPaper-elevation1 {
       box-shadow: inset 0 1px 0 0 $primary-light;
    }

    .MuiTableCell-head{
        color: $secondary-color;
        font-weight: bold;
    }

    .MuiTableCell-body {
        color: $secondary-color;
    }

    .MuiTableCell-root {
        padding: 6px 10px;
        line-height: 1.53;
        letter-spacing: normal;
        border: none;
        font-family: "Rubik", "Helvetica", "Arial", sans-serif;
    }

    .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: rgba(96, 193, 247, 0.1);
    }

    .table-action,
    .MuiTablePagination-root {
        background-color: #eef4f9;
    }
}
