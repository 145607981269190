// Authentication Templates
//
// This partial contains the styles for the Register, Login
// Forgot Password and Reset Password templates.
.login-page {
    margin-left: -60px;
    .site-brand {
        position: absolute;
        top: 25px;
        right: 40px;
    }

    .branding-image {
        max-width: 160px;
    }

    .toggle-password {
        position: absolute;
        right: 10px;
        z-index: 1;
        top: 38px;
    }
}
