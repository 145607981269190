// Reboot / FA Improvements

// HTML
html {
  font-size: 16px;
  font-weight: 500;

  // Adjust font sizes for 144dpi+ (including retina).
  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi) {
    font-size: 16px;
    font-weight: 400;
  }
}


// Body
body {
  background: $body-background;
  background-image: radial-gradient(circle at 50% 0, #f1f8ff, #eef4f9);
  font-size: $body-font-size;
  font-weight: 500;

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    font-size: 13px;
    font-weight: 400;
  }
}

body,
p { color: #002060 }


// Links
a {
  color: $primary-color;

  @include hover {
    color: darken($primary-color, 15%);
  }
}


// Labels
label {
  font-weight: 400;
}


// Datepicker adjustments
.datepicker {
  font-size: .75rem;
  padding: 0.625rem;

  table tr td,
  table tr th {
    width: 1.875rem;
    height: 1.875rem;
  }
}

hr {
    margin-top: 4px;
    margin-bottom: 4px;
    border-color: #d8e8f4;
}
