.btn-dropdown-custom {
  background-color: white!important;
  color: #002060!important;
  border: 1px solid lightgrey!important;
  margin-left: 5px;
}
.dropdown-menu-custom {
  min-width: 7rem;
}

.btn-user-filter {
  color: #185597;
  border-color: #185597;
  background-color: white;
  box-shadow: none;
  &:focus {
    color: white;
    border-color: #185597;
    background-color: #185597;
    box-shadow: none;
  }
}

.dropdown-item-custom {
  padding: 5px 10px;
}
