.score-logic-setup__navigation {
    width: 219px;
    border: 1px solid #d8e8f4;
    background-color: white;
    margin-top: 7px;
    border-radius: 4px;

    span {
        line-height: 18px;
        display: inline-block;
    }

    .link {
        font-size: 14px;
        display: flex;
        cursor: pointer;
        &.active .label {
            color: #002060;
        }

        &.current .label {
            color: #60c1f7;
        }
    }

    .label {
        padding: 10px;
        color: #a1b6ca;
    }

    .counter {
        font-weight: bold;
        background-color: #eef4f9;
        padding: 10px;
        flex: 0 0 40px;
    }
}
