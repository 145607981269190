.grade-container {
  display: grid;
  grid-template-columns: auto 200px;
  justify-content: space-between;

  .grade-grids {
    display: grid;
    grid-template-columns: auto 120px;
    &-final-title-grade {
      margin: 30px auto 0 10px;
    }
    &-final-title-points {
      margin: 0 auto 0 10px !important;
    }
    &-final-score-grade {
      margin: 35px auto auto 20px;
    }
    &-final-score-points {
      margin: 5px 20px;
    }
  }
}

.rotateA {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transform-origin: bottom center;
  transform: rotateZ(470deg);
}

.rotateB {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transform-origin: bottom center;
  transform: rotateZ(408deg);
}

.rotateC {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transform-origin: bottom center;
  transform: rotateZ(355deg);
}

.rotateD {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transform-origin: bottom center;
  transform: rotateZ(304deg);
}

.rotateE {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transform-origin: bottom center;
  transform: rotateZ(245deg);
}

.log-date {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #a1b6ca;
}

.log-sub-title {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #a1b6ca;
}
