// Custom forms adjustments

.main-content .auth-form {
    margin-top: 20%;

    .card,
    .card-body {
        border-radius: 0;
    }
}

.has-error {
    label {
        color: red;
    }

    input:focus,
    input {
        border-color:red;
        box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
    }

    .help-block {
        color: red;
        font-size: 80%;
    }
}

.custom-select {
    border: 1px solid #c3d1de;
}

.custom-select-sm {
  font-size: $font-size-sm;
  border-radius: 3px;
}

.custom-select-lg {
  border-radius: 3px;
}

// Adjust the custom control line height.
.custom-control {
  line-height: 1.5rem;
}


// Adjust custom controls disabled states.

// Custom checkboxes
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label {
  &::after {
    border-color: $custom-control-disabled-color;
  }
}

// Custom radios
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label {
  &::after {
    background: $custom-control-disabled-color;
  }
}

// Custom toggle
.custom-toggle .custom-control-input:checked:disabled ~ .custom-control-label {
  &::after {
    background: $custom-control-disabled-color;
  }
}
