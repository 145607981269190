// Contextual variations for the new colors
@each $color, $value in $new-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }

  .badge-outline-#{$color} {
    background: none;
    border: 1px solid $value;
    color: $value;
  }
}

// Accent color badge variations
.badge-accent {
  @include badge-variant($accent-color);
}

// Accent color outline badge variation
.badge-outline-accent {
  background: none;
  border: 1px solid $accent-color;
  color: $accent-color;
}

.badge {
  border-radius: 2px;
  border: solid 0.5px;
  line-height: 1;

  &-success,
  &-complete {
    background-color: #edfbf6;
    border-color: #a3edd3;
    color: #32d296;
  }

  &.approved,
  &-info,
  &-pending,
  &-progress {
    background-color: #d8eafc;
    border-color: #bad7f5;
    color: #1e87f0;
  }

  &.declined,
  &-danger,
  &-reject,
  &-error,
  &.refused {
    background-color: #fef4f6;
    border-color: #fef4f6;
    color: #f0506e;
  }
}

.badge-xs {
  &.mfi_supervisor {
    color: #1e87f0;
    background-color: #d8eafc;
    border-color: #bad7f5;
  }

  &.mfi_credit_analyst {
    color: #faa05a;
    border: solid 0.5px #faa05a;
    background-color: #fff1e6;
  }

  &.mfi_load_officer {
    background-color: #edfbf6;
    border-color: #a3edd3;
    color: #32d296;
  }

  &.review {
    color: #faa05a;
    border: solid 0.5px #faa05a;
    background-color: #fff1e6;
  }

  &.approved {
    background-color: #edfbf6;
    border-color: #a3edd3;
    color: #32d296;
  }

  &.incomplete {
    background-color: #b20000;
    border-color: #b20000;
    color: #ffffff;
  }
}
