// Main content adjustments

.main-content {
    margin-left: 120px;

     > .main-content-container.container-fluid {
        min-height: calc(100vh - #{$main-navbar-height + $main-footer-height});

    }
}

.sidebar-open .main-content {
    margin-left: 250px;
}

.icon-sidebar-layout {
    &.sidebar-open .main-content {
        margin-left: 0;
    }
}



  .login-page::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: top right;
    background-image: url('../../../../assets/images/icons/world.svg');
    opacity: 0.1;
    filter: blur(1px);
    z-index: -1;
  }
