// Buttons Adjustments

//
// White and black button adjustments specific to FA Dashboards.
// If you want to use the default FA buttons just comment the
// styles below.
//

.btn {
    border-radius: 3px;
    &,
    &.btn-primary{
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    }
    &[disabled] {
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #b1c5d8;
        border-color: transparent;
    }

    &.thin {
        box-shadow: none;
        padding: 0 8px 5px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 5px solid rgba(16, 125, 84, 0.1);
        color: var(--primary);
        &[disabled],
        &.decline {
            color: #a1b6ca;
            border-bottom-color: #eef4f9;
        }

        &:focus,
        &:hover {
            box-shadow: none;
            background-color: transparent;
            border-bottom-color: var(--primary);
        }
    }

    &.thin.delete {
        color: #f0506e;
        border-bottom-color: rgba(240, 80, 110, 0.1);
        &:focus,
        &:hover {
            border-bottom-color: #f0506e;
        }
    }
}

.btn.dropdown-toggle {
    padding: 6px 13px;
    font-size: 12px;
    background-color: $accent-color;
    border-color: $accent-color;
}

.btn.dropdown-toggle__no-caret::after {
    display: none;
}

.action-cell .btn.btn-primary.btn-disabled,
.btn.btn-disabled {
   font-size: 10px;
   background-color: #d4e5f9;
    border-color:#d4e5f9;
    color: black;
}

.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    &:hover,
    &:focus {
        box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #2e9ddb;
        border-color: #2e9ddb;
    }
}

.btn-secondary {
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #002060
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #2e9ddb;
    border-color: #2e9ddb;
}

.btn-white,
.btn-black {
  // Set the defaults to use the accent color.
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    box-shadow: none !important;
    background-color: $accent-color;
    border-color: $accent-color;
    color: $white;

    + .btn {
      border-left: 1px solid $accent-color;
    }
  }

  &:hover {
    background: $white;
    border-color: $white;
    box-shadow: 0px 0.125rem 0.625rem rgba($reagent-gray, .2),
                0 0.0625rem 0.125rem rgba($reagent-gray, .3);
  }
}

// White button accent color modifiers.
// Selector specificity to override existing active state.
.btn.btn-white {
  border: 1px solid $border-color;
  color: $fiord-blue;

  @mixin btn-active-modifier($color, $value) {
    &:not([disabled]):not(.disabled).active-#{$color} {
      &.active,
      &:active {
        background-color: $value;
        border-color: $value;
        color: color-yiq($value);

        & + .btn {
          border-left: 1px solid red;
        }
      }
    }
  }

  // Existent theme colors
  @each $color, $value in $theme-colors {
    @include btn-active-modifier($color, $value);
  }

  // New colors
  @each $color, $value in $new-colors {
    @include btn-active-modifier($color, $value);
  }
}

// Button variations for the new colors.
@each $color, $value in $new-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

// Button variation for the accent color.
.btn-accent {
  @include button-variant($accent-color);
}

// Outline button variations for the new colors.
@each $color, $value in $new-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Outline button variation for the accent color.
.btn-outline-accent {
  @include button-outline-variant($accent-color);
}

.btn-actions {
    border-radius: 3px;
    border: 1px solid #c3d1de;
    background-image: linear-gradient(to bottom, #f8fafd, #eef4f9 );
    color: #002060;
    font-size: 11px;
    padding: 4px 14px;
    line-height: 20px;
    &:active,
    &:hover {
        color: #002060;
    }
}
