input.form-control {
  border-radius: 2px;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #c3d1de;
  background-color: white;
  height: 36px;
  &:focus {
    color: #495057;
    border-color: $accent-color;
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1),
      0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
  }
}

textarea.form-control {
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
}

.custom-control-label::before {
  border: 1px solid #60c1f7;
  border-radius: 2px;
  background-color: #d6effc;
}

.select-questionnaire {
  width: 431px;
}

.select-file {
  .file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-upload + label {
    border-radius: 3px;
    border: solid 1px #c3d1de;
    background-color: #fafbff;
    padding: 0px 3px;
  }

  .file-upload + label span {
    width: 200px;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    width: 431px;
    height: 35px;
    line-height: 35px;
    padding-left: 10px;
  }

  .file-upload + label strong {
    height: 100%;
    display: inline-block;
    line-height: 29px;
    text-align: center;
    color: #002060;
    width: 123px;
    height: 29px;
    padding: 0 7px;
    margin-left: auto;
    border-radius: 2px;
    border: solid 1px rgba(195, 209, 222, 0.68);
    background-image: linear-gradient(
      to bottom,
      var(--white),
      var(--primary-light)
    );
  }

  .file-upload + label span,
  .file-upload + label strong {
  }
}
