.viewDocuments {
    margin-top: 2rem;
    display: inline;

    &__document {
        padding: 0.4rem 1rem;
        border-bottom: thin solid rgb(213, 211, 211);
        display: flex;
        justify-content: space-between;
    }
}

button.remove_all {
    align-items: flex-start;
    color: white;
    background: rgb(0, 141, 106);
    padding: 4px 15px;
    border-radius: 5px;
}

button.remove_file {
    align-items: flex-start;
    margin-left: 70px;
    color: white;
    background: rgb(0, 141, 106);
    padding: 4px 15px;
    border-radius: 5px;
}
