.comments {
    display: flex;
    flex-direction: column;
    &-empty {
      margin: 30px auto;
    }
}

.comment-left {
    margin: 10px 0;
    margin-right: auto;

    .content {
        padding: 20px;
        background-color: #eef4f9;
        color: #002060;
        position: relative;
        border-radius: 7px;

        &:after {
            left: 0;
            right: auto;
            border-left: 10px solid #eef4f9;
            border-right: 10px solid transparent;
            border-top: 10px solid #eef4f9;
            border-bottom: 10px solid transparent;
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            top: 57px;
        }
    }

    .meta {
        color: #a1b6ca;
        margin-bottom: 10px;

        .name {
            color: #052563;
        }
    }
}


.comment-right {
    margin: 10px 0;
    margin-left: auto;

    .content {
        border-radius: 7px;
        padding: 20px;
        background-color: #60c1f7;
        color: white;
        position: relative;
        &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            right: 0;
            top: 57px;
            border: 15px solid;
            border-left: 10px solid transparent;
            border-right: 10px solid #60c1f7;
            border-top: 10px solid #60c1f7;
            border-bottom: 10px solid transparent;
          }
    }

    .meta {
        color: #a1b6ca;
        margin-bottom: 10px;

        .name {
            color: #052563;
        }
    }
}
