.dashboard-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: -15px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.charts-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  width: 100%;
}

.chart-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: calc(33.33% - 20px); /* Adjust width to fit 3 items per row */
}

.chart-item h4 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}
