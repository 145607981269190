.react-datepicker__input-container {
  .form-control {
    width: 670px;
  }
}

.mfi-error {
  color: red;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: transparent;
}
