.ag-theme-alpine {
  --ag-header-foreground-color: white;
  --ag-header-background-color: var(--primary);
}

.border {
  border-top: 10px solid var(--primary) !important;
}

.profile-details-row {
  width: 85%;
  margin: auto;
}

.header-icon {
  display: inline;
  margin-left: 3px;
}

button:focus {
  outline: 0;
}

.assign-empty-message {
  padding: 10px;
}

.tooltip-container {
  z-index: 1;
}

.view-btn {
  color: #3083ff;
  text-decoration: underline;
}
