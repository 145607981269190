.application-edit-form  {
    .fields__group {
        width: 500px;
        @media only screen and (min-width: 1280px) and (max-width: 1439px) {
            width: 400px;
        }
    }
    .fields__group-wrapper {
        display: grid;
        grid-gap: 27px;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: 'row dense';
        grid-auto-rows: minmax(10px, auto);
    }

    .form-group-section {
        font-size: 22px;
        font-weight: 500;
        color: #002060;
        border-bottom: 7px solid #eef4f9;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    label {
       color: #002060;
    }

    input.form-control {
        border-radius: 2px;
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #c3d1de;
        background-color: #fff;
        height: 40px;
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: #f5f6f7;
    }

    .custom-control-label::before {
        border: 1px solid #e8e8e8;
        background-color: #f5f6f7;
    }

    .form-group {
        position: relative;
    }

    .form-input-note {
        padding: 20px;
        border-radius: 3px;
        border: solid 1px #a3edd3;
        background-color: #edfbf6;
    }

    .flex label {
        width: auto;
    }

    .form-date-value {
        border-radius: 2px;
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #c3d1de;
        background-color: #fff;
        height: 40px;
        line-height: 27px;
        padding: 0.4375rem 0.75rem;
        font-size: 0.8125rem;
        color: #495057;
    }

    .react-datepicker-wrapper {
        position: absolute;
        top: 27px;
        width: 100%;
    }

    .react-datepicker__input-container {
        position: relative;
        display: block;
        input {
            opacity: 0;
        }
    }

    .form-one {
        display: flex;
        align-items: center;
    }

    .label-form{
        margin-right: 20px;
    }

    .checkbox{
        margin-left: 40px;
    }
    .custom-map {
        width: 100%;
        height: 100%;
        position: relative;
      }
}
