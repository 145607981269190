form {
  box-shadow: 3px 2px 2px 0 rgba(119, 151, 178, 0.16);
  background-color: #ffffff;
  margin-top: 7px;
}

.mini-heading-top,
.items-group h4 {
  font-size: 16px;
  color: #002060;
  margin-bottom: 0;
}

.mini-heading-top {
  border-radius: 5px;
  border: solid 1px var(--primary-light-border);
  background-image: linear-gradient(to bottom, #eef4f9, #f8fafd);
  padding: 10px 14px;
  margin-bottom: 10px;
  line-height: 1;
  font-weight: bold;
}

.flex > label:not(.custom-checkbox):not(.custom-radio) {
  width: 180px;
}

.add-tdcv {
  .formula-build {
    border-radius: 2px;
    border: 1px solid #c3d1de;
    height: 78px;
    background-color: #f7fafd;
  }

  .btn {
    padding-left: 0;
    padding-right: 0;
    color: #a1b6ca;
    border: none;
    box-shadow: none;
    background: transparent;
    border-bottom: 5px solid #eef4f9;

    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
      color: #a1b6ca;
      background: transparent;
      border-bottom: 5px solid #eef4f9;
    }
  }
}

.setup-questionnaire-complete {
}

.setup-questionnaire-incomplete {
  height: 100%;
}

.custom-radio label {
  width: auto;
}

.content {
  margin: 16px;
}

.wizard-actions {
  background-color: #f8fbff;
  padding: 10px 20px;
  display: flex;
  box-shadow: inset 0 1px 0 0 var(--primary-light);

  button {
    padding: 10px 25px;
    font-size: 17px;
    line-height: 1;
  }

  button:first-child {
    margin-left: auto;
  }

  .prev {
    margin-left: auto;
    margin-right: 15px;
  }
}

.main-content .table-wrapper {
  .MuiTableCell-root {
    padding: 10px;
  }

  tbody tr {
    border-bottom: solid 1px #eff5fc;
  }
}

.items-holder {
  border-radius: 2px;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #c3d1de;
  background-color: white;
  min-height: 150px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
}

.variable-mpesa {
  grid-column: span 2;
}

.variable-item {
  min-width: 45px;
  height: -webkit-fill-available;
  min-height: 28px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  border: solid 0.5px #a3edd3;
  background-color: #e0fbf1;
  color: var(--primary);
  font-size: 10px;
  cursor: pointer;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__code-K {
    grid-column: span 2;
  }

  &-active {
    border-color: #0d724c;
    background-color: #0d724c;
    color: white;
  }

  span {
    display: inline-block;
  }

  &__label {
    margin-right: 5px;
  }

  &__delete {
    color: red;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 2px;
  }
}

.assumptions .items-holder .variable-item {
  color: #002060;
  border: solid 0.5px #bad7f5;
  background-color: #d8eafc;

  &-active {
    border-color: #002060;
    background-color: #002060;
    color: white;
  }
}

.site-modal.assumptions-type {
  width: 900px;

  .form-group > label {
    width: 120px;
  }

  .group.form-group > label {
    width: auto;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiTableCell-root {
    padding: 5px;
  }

  .legend-head {
    height: 42px;
  }

  .legend-row {
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    font-family: Rubik;
  }

  .MuiTableRow-head .MuiTableCell-head {
    padding: 10px;
    background-color: #eff5fc;
    font-weight: bold;
    text-align: center;
  }

  .MuiTableCell-body {
    border-bottom: none;

    input {
      width: 100%;
    }
  }

  .legend-label-rotate-right {
    left: -85px;
    top: 50%;
  }
}

.tdcv .items-holder .variable-item {
  color: #faa05a;
  border-color: #faa05a;
  background-color: #fff1e6;

  &-active {
    border-color: #faa05a;
    background-color: #faa05a;
    color: white;
  }
}

.formula-build {
  .item-operator {
    width: 27px;
    height: 28px;
    border-radius: 2px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #60c1f7;
    color: white;
    border: none;
    padding: 0;
    font-size: 1em;
    line-height: 28px;
  }
}

.formula-operators {
  right: 0;
  top: 0;

  .btn-tertiary {
    width: 27px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    border-radius: 2px;
    padding: 0;
    margin: 1px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #002060;
    border-color: #002060;
    color: white;
    text-align: center;

    &:hover {
      color: white;
    }
  }

  .btn-secondary,
  .btn-primary {
    font-size: 11px;
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    margin: 1px;
  }
}

.formula-list {
  overflow-y: scroll;
  max-height: 400px;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.07);
}

.formula-list__item {
  margin-bottom: 10px;
  border-bottom: 1px solid #c3d1de38;

  &:last-child {
    margin-bottom: 30px;
  }

  &--detail {
    padding: 3px 0;
  }

  &--actions {
    margin-top: 10px;
  }

  .label {
    color: #a1b6ca;
    width: 250px;
    display: inline-block;
  }

  .detail {
    color: #002060;
  }
}

@media only screen and (max-width: 1440px) {
  .score-logic.col-lg-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.cashflow-statements {
  color: #002060;

  &__title-bar > div {
    padding: 14px 10px;
  }

  &__row > span {
    overflow: hidden;
    display: inline-block;
  }

  &__row {
    border-bottom: 1px solid #eff5fc;
  }

  &__title-bar {
    background-color: #eef4f9;
  }

  &__label--code,
  &__code {
    width: 38px;
    padding: 10px;
    background-color: #eff5fc;
  }

  &__code {
    border-right: 1px solid #d4e5f9;
  }

  &__action--add-code1,
  &__action--add-code2,
  &__action--add-code3,
  &__description {
    padding: 10px;
  }

  &__label--tdcv,
  &__label--emcv {
    width: 230px;
  }

  &__action--add {
    width: 230px;
    padding: 4px 10px;

    &:last-child {
      border-left: 1px solid #eef4f9;
    }
  }

  &__action--add--plus {
    width: 24px;
    height: 24px;
    background-color: #60c1f7;
    color: white;
    text-align: center;
    cursor: pointer;
  }

  &__operations {
    width: 350px;
  }

  &__expression {
    background-color: #eff5fc;
    width: 100%;
    height: 100%;
    display: block;
    padding: 10px;
    font-weight: bold;
  }

  &__add-variable {
    .items-group {
      width: 100%;
    }
  }
}

.scoring-variables {
  color: #002060;

  &__list {
    background-color: #eef4f9;
  }

  &__title-label,
  &__entry-title-text,
  &__action--add,
  &__configuration {
    background-color: #fff;
  }

  &__entry-number,
  &__entry-code,
  &__title-label,
  &__entry-title-text,
  &__title-bar > div,
  &__configuration-condition,
  &__configuration-maxScore,
  &__configuration-value {
    padding: 14px 10px;
  }

  &__title-bar > div {
    font-weight: bold;
  }

  &__title,
  &__entry {
    width: 100%;
  }

  &__entries {
    width: 100%;
    &--add-new {
      width: 170px;
    }
  }

  &__configuration-value,
  &__configuration-condition,
  &__configuration-maxScore,
  &__label--condition,
  &__label--threshold,
  &__label--max-points {
    width: 120px;
  }

  &__action--add,
  &__entry-title,
  &__configurations,
  &__configuration {
    border-top: 1px solid #eff5fc;
  }

  &__configuration-spacer {
    height: 49px;
    background-color: #fff;
  }

  &__row > span {
    overflow: hidden;
    display: inline-block;
  }

  &__row {
    border-bottom: 1px solid #eff5fc;
  }

  &__title-bar {
    background-image: linear-gradient(to bottom, #f8fbfe, #eff5fc);
    box-shadow: inset 0 1px 0 0 #eef4f9;
  }

  &__label--description,
  &__entry-title {
    width: 290px;
  }

  &__label--code,
  &__entry-number,
  &__number {
    width: 38px;
    padding: 10px;
    background-color: #eff5fc;
  }

  &__entry-number {
    margin-left: -38px;
    margin-top: 18px;
  }

  &__label--codes,
  &__action--add {
    width: 160px;
    height: 49px;

    &:last-child {
      border-left: 1px solid #eef4f9;
    }
  }

  &__action--add--plus {
    width: 24px;
    height: 24px;
    background-color: #60c1f7;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 4px;
  }

  &__title-label {
    font-weight: bold;
    width: 455px;
  }

  .btn-primary {
    background-color: #60c1f7;
    border-color: #60c1f7;
    padding: 8px 10px;
    margin-top: 10px;
  }

  &__configuration-edit.btn-success {
    padding: 4px 8px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #60c1f7;
    border-color: #60c1f7;

    svg {
      height: 1em;
      width: 1em;
    }
  }

  &__configuration-delete.btn-danger {
    padding: 4px 8px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #f0506e;
    border-color: #f0506e;

    svg {
      height: 1em;
      width: 1em;
    }
  }
}
