.payment-details-dialog {
  .MuiDialogTitle-root {
    padding: 0 30px;
    text-align: center;

    & .MuiTypography-root {
      font-weight: 600;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
      font-size: 1.625rem;
      color: #3d5170;
    }
  }

  .paper {
    padding: 5px;
    color: black;

    .bold-text {
      font-weight: bold;
    }
  }
}
