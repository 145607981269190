.filter {
    .MuiOutlinedInput-input {
        padding: 10.5px 14px !important;
    }

    // override default input[type="date"] color
    input[type="date"]::before {
        color: #999999;
        content: attr(placeholder);
    }
    input[type="date"] {
        color: #ffffff;
    }
    input[type="date"]:focus,
    input[type="date"]:valid {
        color: #666666;
    }
    input[type="date"]:focus::before,
    input[type="date"]:valid::before {
        content: "" !important;
    }
}
