// Notification SETUP
@mixin flexbox-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

$color-white: #fff;
$color-black: #000;

$theme-material-light: (
  statusBar: #e0e0e0,
  appBar: #f5f5f5,
  appBackground: #fafafa,
  modalAndCard: #fff,
  textPrimary: rgba(#000, 0.87),
  textSecondary: rgba(#000, 0.54),
  textDisabledAndContext: rgba(#000, 0.38),
  dividers: rgba(#000, 0.12),
);

$theme-material-dark: (
  statusBar: #000,
  appBar: #212121,
  appBackground: #303030,
  modalAndCard: #424242,
  textPrimary: rgba(#fff, 1),
  textSecondary: rgba(#fff, 0.7),
  textDisabledAndContext: rgba(#fff, 0.5),
  dividers: rgba(#fff, 0.12),
);

$theme-app: (
  base: #1e87f0,
  lighter: #1e87f0,
  darker: #1e87f0,
);

@function theme-light($key) {
  @if map-has-key($theme-material-light, $key) {
    @return map-get($theme-material-light, $key);
  } @else {
    @warn "No such key in map";
  }
}
@function theme-dark($key) {
  @if map-has-key($theme-material-dark, $key) {
    @return map-get($theme-material-dark, $key);
  } @else {
    @warn "No such key in map";
  }
}
@mixin apply-child-themes($color, $properties...) {
  .t-material-light & {
    @each $property in $properties {
      #{$property}: theme-light($color);
    }
  }
  .t-material-dark & {
    @each $property in $properties {
      #{$property}: theme-dark($color);
    }
  }
}
@mixin hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
@function theme-app($key) {
  @if map-has-key($theme-app, $key) {
    @return map-get($theme-app, $key);
  } @else {
    @warn "No such key in map";
  }
}

.container {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 200px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}

.artboard {
  @include flexbox-c-c;

  &.t-material-light {
    background-color: map-get($theme-material-light, appBackground);
  }

  &.t-material-dark {
    background-color: map-get($theme-material-dark, appBackground);
  }
}

.let-me-know {
  bottom: 10px;
  color: rgba($color-white, 0.83);
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  position: absolute;
  right: 10px;
  z-index: 0;

  i {
    padding: 0 3px;
    color: rgba(232, 41, 41, 0.9);
    font-size: larger;
  }
}

@keyframes ringing {
  0%,
  100% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(10deg);
  }

  40%,
  80% {
    transform: rotate(-6deg);
  }

  60% {
    transform: rotate(6deg);
  }
}

@keyframes clapp {
  $_x: 6px;

  0%,
  100% {
    transform: translate($_x, 18px) rotate(0);
  }

  20% {
    transform: translate($_x + 4px, 18px) rotate(-10deg);
  }

  40% {
    transform: translate($_x - 4px, 18px) rotate(10deg);
  }

  60% {
    transform: translate($_x + 3px, 18px) rotate(-8deg);
  }

  80% {
    transform: translate($_x - 3px, 18px) rotate(8deg);
  }
}

// REACT COMPONENT
.notification-bell {
  @include apply-child-themes(textDisabledAndContext, color);

  animation-timing-function: ease;
  cursor: pointer;
  transform-origin: 50% 0%;
  position: relative;
  transition: 200ms ease-in-out color;
  user-select: none;

  &:after {
    @include apply-child-themes(appBackground, "border-color");

    background-color: theme-app(lighter);
    border: 2px solid;
    border-radius: 11px;
    content: attr(data-count);
    color: $color-white;
    display: block;
    font-size: 10px;
    font-family: helvetica;
    height: 24px;
    line-height: 18px;
    min-width: 24px;
    padding: 1px;
    right: -1px;
    top: 19px;
    position: absolute;
    text-align: center;
    transition: 100ms ease-in-out all;
  }

  &[data-count="0"] {
    &:after {
      @include hidden-visually;
    }
  }

  &.is-muted {
    &:after {
      content: "";
      color: transparent;
      height: 2px;
      min-width: 0;
      padding: 2px;
      right: -1px;
      top: 19px;
      width: 2px;
    }
  }

  &:not([data-count="0"]) {
    @include apply-child-themes(textPrimary, color);

    &.is-muted {
      @include apply-child-themes(textSecondary, color);
    }
  }

  &:hover {
    @include apply-child-themes(textDisabledAndContext, color);

    &:after {
      background-color: theme-app(base);
    }
  }

  &.is-animating {
    animation: ringing 800ms;

    .notification-bell__clapper {
      animation: clapp 800ms;
    }
  }

  .notification-bell__bow,
  .notification-bell__clapper {
    fill: currentColor;
  }

  svg {
    overflow: visible !important; //override reset
  }
}

.notification-bell__clapper {
  animation-timing-function: inherit;
  transform: translate(6px, 18px) rotate(0);
  transform-origin: inherit;
}

.notification-bar .dropdown-menu {
  width: 500px !important;
}

.notification {
  &-wrapper {
    padding: 20px;
  }
  &-header {
    display: flex;
    flex-wrap: nowrap;
    &-title {
      width: 50%;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.17;
      letter-spacing: normal;
      color: #002060;
    }
    &-close {
      text-align: end;
      width: 50%;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #60c1f7;
      cursor: pointer;
    }
  }
  &-footer {
    display: flex;
    flex-wrap: nowrap;
    &-title {
      text-align: end;
      width: 50%;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #60c1f7;
      cursor: pointer;
    }
    &-read {
      text-align: end;
      width: 50%;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #60c1f7;
      cursor: pointer;
    }
  }

  &-messages {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    padding: 11px 0;
    letter-spacing: normal;
    color: #002060;
  }
}
