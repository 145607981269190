.pagination {
  padding-bottom: 1rem;
  justify-content: flex-end;

  &__previous-disabled {
    color: rgb(170, 170, 170);
  }
}

.clientList {
  width: 100%;
  margin-bottom: 3.5rem;

  .table-mfiId, .table-id, .table-action, [data-key="mfiId"], [data-key="id"] {
    display: none;
  }
  .action, .tableAction {
    position: absolute;
    right: 10px;
    display: block;
    background-color: rgb(240, 238, 238);
    padding-right: 0;
    margin-right: 0%;
    width: 90px;
  }
}
